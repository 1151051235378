<template>
  <div
    class="modal fade"
    id="sentModal"
    tabindex="-1"
    aria-labelledby="sentModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sentModalLabel">Weiterleitungen anzeigen</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="showBoxnumber" class="form-label">Box Nummer</label>
            <input
              type="number"
              class="form-control"
              id="showBoxnumber"
              v-model.number="boxNumberInput"
              @input="loadByNumber"
            />
          </div>
          <div v-if="entries">
            <div v-for="entry in entries" v-bind:key="entry.id">
              <b>Weitergeleitet von</b>: {{ entry.who }}<br>
              <b>Weitergeleitet am</b>: {{ formatTime(entry.forwardedTime) }}<br>
              <b>Notizen</b>:<br>{{ entry.message }}
              <hr>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="this.boxNumberInput = undefined; entries = null;"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { getForwarded } from "@/api";


export default defineComponent({
  data: () => {
    return {
      entries: null,
      boxNumberInput: null
    };
  },
  props: {
    callback: Function
  },
  methods: {
    loadByNumber() {
      this.entries = [];
      if (this.boxNumberInput == null) return;
      getForwarded(this.boxNumberInput).then(function (response) {
          this.entries = response.data.data;
      }.bind(this));
    },
    formatTime(time) {
      let date = new Date(time);
      return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes();
    }
  }
});
</script>