const axios = require('axios');

const URL = "https://ba.factorycampus.de"

export function getUserInfo() {
    return axios.get(URL + '/api/status');
}

export function getEntry(boxNumber) {
    return axios.get(URL + '/api/baddress/' + boxNumber);
}

export function getEntries(query) {
    let queryString = new URLSearchParams(query).toString();
    return axios.get(URL + '/api/baddress?' + queryString);
}

export function sendMail(boxNumber) {
    return axios.post(URL + '/api/baddress/' + boxNumber + '/mail')
}

export function sendPackageMail(boxNumber) {
    return axios.post(URL + '/api/packages/' + boxNumber + '/mail')
}

export function createEntry(entry) {
    return axios.post(URL + '/api/baddress', entry);
}

export function editEntry(boxNumber, entry) {
    return axios.put(URL + '/api/baddress/' + boxNumber, entry);
}

export function deleteEntry(boxNumber) {
    return axios.delete(URL + '/api/baddress/' + boxNumber);
}

export function getForwarded(boxNumber) {
    return axios.get(URL + '/api/forwards?box=' + boxNumber);
}

export function createForward(entry) {
    return axios.post(URL + '/api/forwards', entry);
}