import { createRouter, createWebHistory } from 'vue-router';
import { getUserInfo } from '@/api';

import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath === "/") {
    getUserInfo().then((response) => {
      if (response.data.loggedIn) next("/dashboard");
      else next();
    })
  } else if (to.fullPath === "/dashboard") {
    getUserInfo().then((response) => {
      if (!response.data.loggedIn) next("/");
      else next();
    })
  } else {
    next("/")
  }
})

export default router
