<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <a class="navbar-brand" href="#"><img src="@/assets/logo-fc-bo-light.svg"></a>
      <p style="color: white">{{name}} - <a style="color: red; cursor: pointer;" @click="logout">Logout</a></p>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from 'vue'

import { getUserInfo } from '@/api';

export default defineComponent({
  data: () => {
    return {
      name: ""
    };
  },
  methods: {
    loadData() {
      getUserInfo().then(function (response) {
        this.name = response.data.userName;
      }.bind(this))
    },
    logout() {
      document.cookie = "session= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      location.href = "/";
    }
  },
  mounted: function() {
    this.loadData();
  }
})
</script>
