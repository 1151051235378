<template>
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editModalLabel">Box {{boxNumber}} bearbeiten.</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="editFirstname" class="form-label">Vorname</label>
            <input
              type="text"
              class="form-control"
              id="editFistname"
              v-model="firstName"
            />
          </div>
          <div class="mb-3">
            <label for="editLastname" class="form-label">Nachname</label>
            <input
              type="text"
              class="form-control"
              id="editLastname"
              v-model="lastName"
            />
          </div>
          <div class="mb-3">
            <label for="editEmail" class="form-label">Email address</label>
            <input
              type="email"
              class="form-control"
              id="editEmail"
              v-model="email"
            />
          </div>
          <div class="mb-3">
            <label for="editCompanyname" class="form-label">Unternehmen</label>
            <input
              type="text"
              class="form-control"
              id="editCompanyname"
              v-model="companyName"
            />
          </div>
          <div class="mb-3">
            <label for="editNotes" class="form-label">Notizen</label>
            <textarea
              type="text"
              class="form-control"
              id="editNotes"
              v-model="notes"
              @change="hasNote = true"
            />
          </div>
          <div class="mb-3">
            <label for="editAddress" class="form-label">Addresse</label>
            <textarea
              type="text"
              class="form-control"
              id="editAddress"
              v-model="address"
              @change="hasAddress = true"
            />
          </div>
          <div class="mb-3">
            <p>Die letzte Benachrichtigung wurde zu folgendem Zeitpunkt gesendet: {{ lastSent }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Abbrechen
          </button>
          <button
            type="button"
            @click="this.delete"
            class="btn btn-danger"
            data-bs-dismiss="modal"
          >
            Eintrag Löschen
          </button>
          <button
            type="button"
            @click="this.edit"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Änderungen speichern
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { getEntry, editEntry, deleteEntry } from "@/api";

export default defineComponent({
  data: () => {
    return {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      notes: "",
      address: "",
      boxNumber: null,
      hasNote: false,
      hasAddress: false
    };
  },
  props: {
    callback: Function,
  },
  methods: {
    edit() {
      if (!this.firstName || !this.lastName || !this.companyName) {
        alert("Vorname, Nachname und Unternehmen müssen angegeben werden.")
        return;
      }
      editEntry(this.boxNumber, {
        firstName: this.firstName,
        lastName: this.lastName,
        companyName: this.companyName,
        email: this.email,
        address: this.hasAddress ? (this.address === "" ? "N/A": this.address) : null,
        notes: this.hasNote ? (this.notes === "" ? "N/A": this.notes) : null
      }).then(() => {
        this.callback();
      });
    },
    load() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.companyName = "";
      this.notes = "";
      this.address = "";
      this.hasNote = false;
      this.hasAddress = false;
      if (this.boxNumber == null) return;
      getEntry(this.boxNumber).then(function (response) {
          let data = response.data.data;
          this.firstName = data.firstName;
          this.lastName = data.lastName;
          this.email = data.email;
          this.companyName = data.companyName;
          if (data.notes !== "N/A") this.notes = data.notes;
          if (this.notes !== "") this.hasNote = true;
          if (data.address !== "N/A") this.address = data.address;
          if (this.address !== "") this.hasAddress = true;
          this.lastSent = data.lastMail;
      }.bind(this));
    },
    delete() {
      deleteEntry(this.boxNumber).then(() => this.callback());
    }
  }
});
</script>
