<template>
  <div v-if="entry.email && (!entry.notifiedToday || isPackage)">
    <button @click="send(entry)" class="btn btn-primary">
      <i v-if="isPackage" class="fas fa-box"></i><i v-else class="fas fa-envelope"></i> {{ isPackage ? "Paket" : "Post" }}
    </button>
  </div>

  <div v-else-if="entry.notifiedToday">
    <i class="fas fa-check"></i> Sent<br>
    <a @click="send(entry)" href="#">
      <i v-if="isPackage" class="fas fa-box"></i><i v-else class="fas fa-envelope"></i> Resend {{ isPackage ? "Paket" : "Post" }}
    </a>
  </div>
  <div v-else></div>
</template>

<script>
import { defineComponent } from "vue";
import { sendMail, sendPackageMail } from "@/api";


export default defineComponent({
  props: {
    entry: Object,
    ignoreNotes: Boolean,
    isPackage: Boolean,
    callback: Function
  },
  methods: {
    send(entry) {
      if (!entry.notes || this.ignoreNotes || entry.notes === "N/A" || confirm(entry.notes)) {
        if (this.isPackage) {
          sendPackageMail(entry.boxNumber).then(() => {if (this.callback) this.callback();})
        } else {
          sendMail(entry.boxNumber).then(() => {if (this.callback) this.callback();})
        }
      }
    },
  },
});
</script>
