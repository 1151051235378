<template>
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="addModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addModalLabel">Adresse {{boxNumber}} hinzufügen</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="addFirstname" class="form-label">Vorname</label>
            <input
              type="text"
              class="form-control"
              id="addFistname"
              v-model="firstName"
            />
          </div>
          <div class="mb-3">
            <label for="addLastname" class="form-label">Nachname</label>
            <input
              type="text"
              class="form-control"
              id="addLastname"
              v-model="lastName"
            />
          </div>
          <div class="mb-3">
            <label for="addEmail" class="form-label">Email address</label>
            <input
              type="email"
              class="form-control"
              id="addEmail"
              v-model="email"
            />
          </div>
          <div class="mb-3">
            <label for="addCompanyname" class="form-label">Unternehmen</label>
            <input
              type="text"
              class="form-control"
              id="addCompanyname"
              v-model="companyName"
            />
          </div>
          <div class="mb-3">
            <label for="addBoxnumber" class="form-label">Box Nummer</label>
            <input
              type="number"
              class="form-control"
              id="addBoxnumber"
              v-model.number="boxNumber"
            />
          </div>
          <div class="mb-3">
            <label for="editNotes" class="form-label">Notizen</label>
            <textarea
              type="text"
              class="form-control"
              id="editNotes"
              v-model="notes"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Abbrechen
          </button>
          <button
            type="button"
            @click="this.create"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Erstellen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { createEntry } from "@/api";

export default defineComponent({
  data: () => {
    return {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      notes: "",
      boxNumber: null,
    };
  },
  props: {
    callback: Function
  },
  methods: {
    create() {
      if (!this.firstName || !this.lastName || !this.companyName || !this.boxNumber) {
        alert("Box Nummer, Vorname, Nachname und Unternehmen müssen angegeben werden.")
        return;
      }
      createEntry({
        firstName: this.firstName,
        lastName: this.lastName,
        companyName: this.companyName,
        email: this.email,
        boxNumber: this.boxNumber,
        notes: this.notes
      }).then(() => {
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.companyName = "";
        this.notes = "";
        this.boxNumber = null;
        this.callback();
      });
    }
  }
});
</script>
