<template>
  <div class="outer" :style="'background-image: url(\''+require('@/assets/bg-auth.png')+'\');'">
    <div class="inner" style="height: 100vh; width: 300px">
    <header class="header">
      <h1 style="color: #f58221" class="title">Business Address Mail</h1>
    </header>
    <div class="card card-body">
      <form name="adminLoginForm" class="form manager form-section">
        <a
            href="/g-oauth/"
            style="background-color: #f58221; color: white; width: 100%"
            class="btn full-width small-radius"
          >
            Login
          </a>
      </form>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "Login",
};
</script>

<style scoped>
.outer{
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.inner{
  margin-left: auto;
  margin-right: auto;
  padding-top: 30vh;
}
</style>
