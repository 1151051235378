<template>
  <section v-if="entry.address && entry.address != 'N/A'">
    <div>
      <button
        class="btn btn-info"
        data-bs-toggle="modal"
        :data-bs-target="'#weiterleitungMail' + entry.boxNumber"
      >
        <i class="fas fa-mail-bulk"></i>
        Weiterleitung
      </button>
    </div>

    <div
      class="modal fade"
      :id="'weiterleitungMail' + entry.boxNumber"
      tabindex="-1"
      :aria-labelledby="'showWeiterleitungMail' + entry.boxNumber"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title" id="showModalLabel">
              Postweiterleitung für {{ entry.companyName }}
            </p>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="entry">
              <div v-if="entry.notes !== '' && entry.notes !== 'N/A'">
                <h5>Notizen:</h5>
                <p
                  v-html="
                    entry.notes
                      .replace('<', '')
                      .replace('>', '')
                      .replace('\n', '<br>')
                  "
                />
              </div>
              <h5>Addresse:</h5>
              <p
                v-html="
                  entry.address
                    .replace('<', '')
                    .replace('>', '')
                    .replace('\n', '<br>')
                "
              />
              <div class="mb-3">
                <label for="sentBox" class="form-label"
                  >Was wurde weitergeleitet?</label
                >
                <textarea
                  type="text"
                  class="form-control"
                  id="sentBox"
                  v-model="sent"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Abbrechen
            </button>
            <button
              type="button"
              @click="this.send"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Neue Weiterleitung speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-else />
</template>

<script>
import { defineComponent } from "vue";

import { createForward } from "@/api";

export default defineComponent({
  props: {
    entry: Object
  },
  data: () => {
    return {
      sent: "",
    };
  },
  methods: {
    send() {
      createForward({
        "boxNumber": this.entry.boxNumber,
        "message": this.sent
      }).then(() => {
        this.sent = "";
      })
    },
  },
});
</script>
