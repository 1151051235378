<template>
  <div class="outer">
    <Navbar />
    <!-- Modals -->
    <AddModal :callback="reload" />
    <EditModal ref="editModal" :callback="reload" />
    <ShowModal :callback="reload" />
    <SentModal />

    <!-- Screen -->
    <div class="card card-body" v-if="entries">
      <div class="card-header">
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <a
              class="nav-link active"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              href="#"
              ><i class="fas fa-plus-square" /> Neuer Eintrag</a
            >
          </li>
          <li class="nav-item" style="margin-left: 10px">
            <a
              class="nav-link active"
              data-bs-toggle="modal"
              data-bs-target="#showModal"
              href="#"
              ><i class="fas fa-search" /> Einzelansicht</a
            >
          </li>
          <li class="nav-item" style="margin-left: 10px">
            <a
              class="nav-link active"
              data-bs-toggle="modal"
              data-bs-target="#sentModal"
              href="#"
              ><i class="fas fa-history" /> Vergangene Weiterleitungen</a
            >
          </li>
        </ul>
      </div>
      <div style="padding: 10px">
        <td><input class="form-control" placeholder="Suche" v-model="search" @input="reload" /></td>
        <table
          style="width: 100%; padding: 10px"
          class="table table-striped table-hover"
        >
          <thead>
            <tr>
              <td scope="col">Box</td>
              <td scope="col">Vorname</td>
              <td scope="col">Nachname</td>
              <td scope="col">Unternehmen</td>
              <td scope="col">Weiterleitung</td>
              <td scope="col">Packet</td>
              <td scope="col">Post</td>
              <td scope="col">Edit</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entry in entries" v-bind:key="entry.boxNumber">
              <td v-text="entry.boxNumber" />
              <td v-text="entry.firstName" />
              <td v-text="entry.lastName" />
              <td v-text="entry.companyName" />
              <td><post-button :entry="entry" :callback="reload" /></td>
              <td><send-button :entry="entry" isPackage :callback="alertSent" /></td>
              <td><send-button :entry="entry" :callback="reload" /></td>
              <td>
                <!-- 
                 I know that this is probably not the best way to do this. But with
                 the way Bootstrap Modals work that seems to be the best option.
              -->
                <button
                  @click="
                    $refs.editModal.boxNumber = entry.boxNumber;
                    $refs.editModal.load();
                  "
                  data-bs-toggle="modal"
                  data-bs-target="#editModal"
                  class="btn btn-warning"
                >
                  <i class="fas fa-edit"></i> Edit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getEntries } from "@/api";

import Navbar from "@/components/Navbar";
import AddModal from "@/components/AddModal";
import EditModal from "@/components/EditModal";
import SentModal from "@/components/SentModal";
import ShowModal from "@/components/ShowModal";
import SendButton from "@/components/SendButton.vue";
import PostButton from "@/components/PostButton.vue";

export default defineComponent({
  components: {
    Navbar,
    AddModal,
    EditModal,
    ShowModal,
    SendButton,
    PostButton,
    SentModal,
  },
  data: () => {
    return {
      entries: null,
      search: ""
    };
  },
  methods: {
    reload() {
      getEntries({
        search: this.search
      }).then((response) => (this.entries = response.data.data));
    },
    alertSent() {
      alert("Gesendet!");
    }
  },
  mounted: function () {
    this.reload();
  },
});
</script>

<style scoped>
.outer {
  height: 100vh;
  width: 100%;
  background-color: #f7f7f7;
}

.card {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  padding: 0;
}

.btn-primary {
  background-color: #0d6efd !important;
}

.btn-warning {
  background-color: #ffc107 !important;
}

thead > th > td {
  font-weight: bold;
}
</style>