<template>
  <div
    class="modal fade"
    id="showModal"
    tabindex="-1"
    aria-labelledby="showModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="showModalLabel">Box ansehen.</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="showBoxnumber" class="form-label">Box Nummer</label>
            <input
              type="number"
              class="form-control"
              id="showBoxnumber"
              v-model.number="boxNumberInput"
              @input="loadByNumber"
            />
          </div>
          <div v-if="entry">
            <div class="data-box"><b>Box: </b> <p v-text="entry.boxNumber" /></div>
            <div class="data-box"><b>Unternehmen: </b> <p v-text="entry.companyName" /></div>
            <div class="data-box"><b>Vorname: </b> <p v-text="entry.firstName" /></div>
            <div class="data-box"><b>Nachname: </b> <p v-text="entry.lastName" /></div>
            <div class="data-box"><b>Notizen: </b> <p v-text="entry.notes" /></div>
            <div class="data-box"><div class="mb-3" v-if="entry.lastSent">
              <p>Die letzte Benachrichtigung wurde zu folgendem Zeitpunkt gesendet: {{ entry.lastSent }}</p>
            </div></div>
            <send-button :entry="entry" :callback="() => {loadByNumber(); callback();}" ignore-notes />
            <send-button :entry="entry" isPackage :callback="() => {loadByNumber(); callback();}" ignore-notes />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { getEntry } from "@/api";

import SendButton from "@/components/SendButton";

export default defineComponent({
  components: {
    SendButton
  },
  data: () => {
    return {
      entry: null,
      boxNumberInput: null
    };
  },
  props: {
    callback: Function
  },
  methods: {
    loadByNumber() {
      this.entry = null;
      if (this.boxNumberInput == null) return;
      getEntry(this.boxNumberInput).then(function (response) {
          this.entry = response.data.data;
      }.bind(this));
    }
  }
});
</script>